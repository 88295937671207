<template>
    <li v-for="(tab, index) in tabs" :key="tab" @click="changeTab(index)" class="tabListItem" :class="{tabListItemActive: currentTab === index}" :title="tab.omschrijving">
        <span class="tabText" style="white-space:nowrap">{{ tab.code }} - {{ tab.omschrijving }}</span>
        <span class="tabNotification" style="display:none; white-space:nowrap;"></span>
        <div class="tabSelect" style="display:none;"></div>
    </li>
</template>

<script>
import { ref } from "vue"

export default {
    name:"tab",
    components: {},
    props: ["tabs"],
    emits: ['tab-switch'],
    setup(props, { emit }){
        const currentTab = ref(0)

        const changeTab = (index) => {
            currentTab.value = index
            emit('tab-switch', index)
        }

        return { currentTab, changeTab }
    }
}

</script>