<template>
    <div class="dataRow" :class="{ odd: isOdd }">
        <div v-for="(value,key, index) in row" :key="index" class="dataColumn verticalCenter" :style="{ width: metaData.fields[index].width}" >
            <div class="text"> {{ value }}</div>
        </div>
    </div>
</template>

<script>

import { computed, toRefs } from 'vue'

export default {
    name: 'AFAS_Row',
    props: ['row', 'rowIndex', 'metaData'],
    setup(props) {

        const { rowIndex } = toRefs(props)

        const isOdd = computed(() => {
            return rowIndex.value % 2 == 0;
        })

        return { isOdd }
    }

}

</script>