<template>
    <div class="tabContainer" style="height: 650px;">
        <!-- tabs -->
        <div class="part pagetile clear" style="visibility: visible;">
            <div class="content">
                <div class="webview desktop">
                    <div class="viewdata normal selectable">
                        <div class="scroll">
                            <div class="grid noWrap">
                                <div class="head">
                                    <!-- header -->
                                    <div class="headerRow">
                                        <!-- headerColumn -->
                                        <div
                                            v-for="field in metaData.fields"
                                            :key="field"
                                            class="headerColumn"
                                            :style="{ width: field.width }"
                                        >
                                            <div class="headerLabel">{{ field.label }}</div>
                                            <div class="resize"></div>
                                        </div>
                                    </div>
                                    <!-- filter -->
                                    <div class="filterRow">
                                        <div
                                            v-for="field in metaData.fields"
                                            :key="field"
                                            class="filterColumn"
                                        >
                                            <div class="filter">
                                                <input
                                                    type="text"
                                                    v-model="field.filter"
                                                    v-on:keyup.enter="filter()"
                                                    class="value"
                                                    style="width: 100%"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- body -->
                                <div class="body">
                                    <div v-if="rows.length == 0" class="dataRow fillOut">
                                        <span
                                            class="noDataWithFilter"
                                        >Geen resultaten.</span>
                                    </div>
                                    <AFAS_Row
                                        v-for="(row, index) in rows"
                                        :key="(row.Itemcode + row.Assortiment)"
                                        :row="row"
                                        :rowIndex="index"
                                        :metaData="metaData"
                                    ></AFAS_Row>
                                    <!-- row -->
                                </div>
                            </div>
                        </div>

                        <div class="navigation">
                            <div class="loading"></div>
                            <div class="bar">
                                <button
                                    @click="pagination(-1)"
                                    v-if="paginationState"
                                    class="webbutton webbutton-text-only webbutton-color-normal cursorpointer"
                                >
                                    <span class="webbutton-text">Vorige</span>
                                </button>
                                <button v-if="rows.length > 19"
                                    @click="pagination(1)"
                                    class="webbutton webbutton-text-only webbutton-color-normal cursorpointer"
                                >
                                    <span class="webbutton-text">Volgende</span>
                                </button>
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import AFAS_Row from '@/components/Row.vue'
import { ref } from "vue"

export default {
    name: 'AFAS_View',
    emits: ['next-take', 'filter'],
    components: {
        AFAS_Row
    },
    props: ['rows', 'metaData'],
    setup(props, { emit }) {

        const paginationState = ref(0)

        const pagination = (value) => {
            paginationState.value = paginationState.value + value
            emit('next-take', value)
        }

        const filter = () => {
            emit('filter')
        }

        return {
            pagination,
            paginationState,
            filter
            
        }

    }


}

</script>