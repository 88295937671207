<template>
    <div class="home">
        <div class="part clear">
            <div>
                <div class="horizontal">
                    <div class="tabListBar">
                        <ul class="tabList">
                            <Tab v-if="tabs" :tabs="tabs" @tab-switch="tabSwitch"></Tab>
                        </ul>
                    </div>
                    <AFAS_View
                        v-if="metaData"
                        :metaData="metaData"
                        :rows="rows"
                        @next-take="fetchNextTake"
                        @filter="filter"
                    ></AFAS_View>
                </div>
                <div v-if="error">
                    <p>Er is een fout opgetreden bij laden van dit Flinker onderdeel</p>
                    <p>{{error}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AFAS_View from '@/components/View.vue'
import Tab from '@/components/Tab.vue'
import { ref, onMounted} from "vue"
import Nprogress from 'nprogress'

export default {
    name: 'Groepering',
    components: {
        AFAS_View,
        Tab
    },
    setup() {
        const loading = ref(true)
        const error = ref(null)
        const rows = ref(null)
        const metaData = ref(null)
        const tabs = ref(null)
        const skip = ref(0)
        const take = ref(20)
        const filterValue = ref(null)
        const activeTab = ref(0)

        const tabSwitch = (index) => {            
            activeTab.value = index
            filter()
        }

        const filter = () => {
            let filterString = {
                "filterfieldids": "Assortiment",
                "filtervalues": tabs.value ? tabs.value[activeTab.value].code: "",
                "operatortypes": "6"
            }

            if (metaData.value) {
                metaData.value.fields.map(field => {
                    if (field.filter) {
                        filterString.filterfieldids = filterString.filterfieldids ? filterString.filterfieldids + `,${field.id}` : `${field.id}`
                        filterString.filtervalues = filterString.filtervalues ? filterString.filtervalues + `,${field.filter}` : `${field.filter}`
                        filterString.operatortypes = filterString.operatortypes ? filterString.operatortypes + ',6' : '6'
                    }
                })
            }

            filterValue.value = filterString.filterfieldids ? JSON.stringify(filterString) : ""
            skip.value = 0
            take.value = 20
            fetchData()
        }

        const getAssortimentsToTabs = (rows) => {
            tabs.value = rows.map(row => {
                return { 'code' : row.Assortiment, 'omschrijving': row.Omschrijving }
            })
        }

        const calculateWidth = () => {
            const totallength = metaData.value.fields.reduce((previousValue, field) => {
                return previousValue + field.length
            }, 0)

            metaData.value.fields = metaData.value.fields.reduce((previousValue, field,) => {
                field.width = (field.length / totallength * 100) < 10 ? "10%" : (field.length / totallength * 100) + "%"
                field.filter = "" //add filter value
                previousValue.push(field)
                return previousValue
            }, [])
        }

        const fetchTabs = () => {
            loading.value = true;
            Nprogress.start()
            // I prefer to use fetch
            // you can use use axios as an alternative
            return fetch(`https://node.flinker.online/vivas/assortiment${window.location.search}`, {
                method: 'get',
                headers: {
                    'content-type': 'application/json'
                }
            })
                .then(res => {
                    // a non-200 response code
                    if (!res.ok) {
                        // create error instance with HTTP status text            
                        const error = new Error(res.statusText);
                        error.json = res.json();
                        throw error;
                    }
                    return res.json();
                })
                .then(json => {
                    // set the response data                    
                    getAssortimentsToTabs(json.rows)
                })
                .catch(err => {
                    error.value = err;
                    // In case a custom JSON error response was provided          
                    if (err.json) {
                        return err.json.then(json => {
                            // set the JSON response message
                            error.value.message = json.message;
                        });
                    }
                })
                .then(() => {
                    loading.value = false;
                    Nprogress.done()
                })
                .then(() => {
                    filter()
                })
        }

        const fetchData = () => {
            loading.value = true;
            Nprogress.start()
            // I prefer to use fetch
            // you can use use axios as an alternative
            return fetch(`https://node.flinker.online/vivas/assortimentregels${window.location.search}&skip=${skip.value}&take=${take.value}&filter=${filterValue.value}`, {
                method: 'get',
                headers: {
                    'content-type': 'application/json'
                }
            })
                .then(res => {
                    // a non-200 response code
                    if (!res.ok) {
                        // create error instance with HTTP status text            
                        const error = new Error(res.statusText);
                        error.json = res.json();
                        throw error;
                    }
                    return res.json();
                })
                .then(json => {
                    // set the response data
                    rows.value = json.rows;

                    //do not replace metadata if exists
                    if (!metaData.value) {
                        metaData.value = json.metaData;
                        calculateWidth(json.metaData)
                    }
                })
                .catch(err => {
                    error.value = err;
                    // In case a custom JSON error response was provided          
                    if (err.json) {
                        return err.json.then(json => {
                            // set the JSON response message
                            error.value.message = json.message;
                        });
                    }
                })
                .then(() => {
                    loading.value = false;
                    Nprogress.done()
                })
        }

        onMounted(() => {
            fetchTabs()
        })

        const fetchNextTake = (next) => {
            skip.value = (skip.value + (next * take.value)) < 0 ? 0 : (skip.value + (next * take.value))
            fetchData()
        }

        return { rows, metaData, tabs, fetchNextTake, filter, tabSwitch, activeTab, error }
    }


}

</script>